import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import loadable from '@loadable/component'
import { Row, Column, Box, Button, Text } from 'components'
import { useSharedState } from 'context/sharedState'
import { createLead } from 'api-utils/lead'
import { useLocation } from 'context/location'
import { teilverkaufLeadValidationSchemaSinglePage } from 'utils/forms'
import MultiSelect from 'components/MultiSelect'
import { Input, Checkbox, SubmissionFailedErrorMessage } from 'components/Forms'
import LocationSelect from 'components/LocationSelect'
import Modal from './Modal'
import { ErrorMessage } from 'components/Forms/Errors'
import { PROPERTY_TYPE_OPTIONS, SALUTATION_OPTIONS } from 'models/Options'
import { formatGermanNumber } from 'utils/numbers'
import EuroCurrencyInput from 'components/EuroCurrencyInput'

const ControlWrapper = (props) => {
  return <Box {...props} sx={{ padding: ['4px 0 ', null, '8px 0'] }} />
}

const CountryCodeSelect = loadable(() =>
  import('components/Forms/CountryCodeSelect')
)
const Select = loadable(() => import('components/Forms/BambusSelect'))

const ControlHeading = ({ children, extraElement = null, sx = {} }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Text
        sx={{
          fontWeight: '600',
        }}
      >
        {children}
      </Text>
      {extraElement}
    </Box>
  )
}

const Lead = ({
  onClose,
  signupReason: signupReasonProp,
  openInCallbackMode,
  formMetadataLSKey,
  amount: amountProp,
  propertyWorth: propertyValuationProp,
  payout: payoutProp,
}) => {
  const { countryCode } = useLocation()

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const [lead, updateLead] = useSharedState()

  const valuationCustomerDefaultValue =
    propertyValuationProp && Number(propertyValuationProp)
  const payoutDefaultValue = payoutProp && Number(payoutProp)
  const showNegativeSchufa = countryCode !== 'AT'

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    errors,
    control,
  } = useForm({
    resolver: teilverkaufLeadValidationSchemaSinglePage,
    defaultValues: {
      initial_signup_reason: signupReasonProp
        ? signupReasonProp
        : openInCallbackMode
        ? 'appointment'
        : 'offer',
      ...(amountProp && { amount: amountProp }),
      property_type: '',
      salutation: '',
      zip: null,
      ...(openInCallbackMode && {
        reachability_hours: null,
      }),
      ...(valuationCustomerDefaultValue && {
        valuation_customer: valuationCustomerDefaultValue,
      }),
      ...(payoutDefaultValue && {
        amount: payoutDefaultValue,
      }),
      ...(showNegativeSchufa && {
        negative_schufa: false,
      }),
    },
  })

  useEffect(() => {
    register('property_type')
    register('salutation')
    register('selected_country')
    register('reachability_hours')
  }, [])

  React.useEffect(() => {
    if (getValues('telephone')) {
      return
    }

    if (selectedCountry) {
      setValue('telephone_prefix', selectedCountry.prefix)
      return
    }

    if (countryCode) {
      setValue('telephone_prefix', countryCode === 'DE' ? '+49' : '+43')
      return
    }
  }, [selectedCountry, countryCode])

  // this sets the selected_country stored in the form needed in validation
  React.useEffect(() => {
    if (selectedCountry) {
      setValue('selected_country', selectedCountry.value)
      return
    }

    if (countryCode) {
      setValue('selected_country', countryCode)
      return
    }
  }, [selectedCountry, countryCode])

  const onSubmit = async (data) => {
    data = { ...data, sign_up_service_dimension: 'partial_ownership' }

    if (signupReasonProp) {
      data = { ...data, initial_signup_reason: signupReasonProp }
    }
    if (formMetadataLSKey && localStorage) {
      data = { ...data, form_metadata: localStorage.getItem(formMetadataLSKey) }
    }
    setLoading(true)

    const telephonePrefix = data.telephone_prefix
    const telephone = data.telephone
    //delete the telephone & telephone_prefix from data as we don't want it spread into the payload
    delete data['telephone_prefix']
    delete data['telephone']

    delete data['selected_country']

    if (!data.reachability_hours) {
      delete data['reachability_hours']
    }

    const payload = {
      ...lead,
      lead: {
        ...lead.lead,
        ...data,
        ...(telephone && {
          telephone: `${telephonePrefix} ${telephone
            .replace(/\ /g, '')
            .replace(/\-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')}`,
        }),
        property_country:
          selectedCountry?.value ??
          (countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode),
        application_country:
          countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode,
        ...(data.valuation_customer && {
          valuation_customer: formatGermanNumber(data.valuation_customer),
        }),
        ...(data.amount && {
          amount: formatGermanNumber(data.amount),
        }),
        ...(showNegativeSchufa &&
          data.negative_schufa && {
            negative_schufa: data.negative_schufa,
          }),
      },
      customEventPayload: {
        ...(data.valuation_customer
          ? {
              property_value: formatGermanNumber(data.valuation_customer),
            }
          : {
              property_value: undefined,
            }),
        ...(data.amount
          ? {
              amount: formatGermanNumber(data.amount),
            }
          : {
              amount: undefined,
            }),
        bmb_country:
          selectedCountry?.value ??
          (countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode),
      },
    }

    const { success, errors } = await createLead(payload)

    if (errors) {
      errors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      setShowSuccess(true)
      updateLead({})
    } else {
      setShowError(true)
    }

    setLoading(false)
  }

  return (
    <Modal
      title={
        showSuccess
          ? 'Danke! Bitte öffnen Sie den Link in Ihrem Postfach um mit der Finanzierungsanfrage fortzufahren.'
          : 'Unverbindliche und kostenlose Anfrage'
      }
      onClose={onClose}
      hideBody={showSuccess}
    >
      {showSuccess ? null : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box py={[0, null, 2]}>
              <LocationSelect
                value={selectedCountry?.value}
                onChange={setSelectedCountry}
              />
            </Box>

            <Row>
              <Column
                size={[1, null, null, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
              >
                <Box py={['0', null, 2]}>
                  <Select
                    options={SALUTATION_OPTIONS}
                    onChange={(option) => {
                      setValue('salutation', option.value, {
                        shouldValidate: true,
                      })
                    }}
                    placeholder="Anrede"
                    hasError={errors.salutation}
                    isSearchable={false}
                  />
                  {errors.salutation && (
                    <ErrorMessage message={errors.salutation.message} />
                  )}
                </Box>
              </Column>
            </Row>

            <Row>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box py={[0, null, 2]}>
                  <Input
                    type="hidden"
                    name="form_version"
                    value="1"
                    ref={register}
                  />
                  <Input
                    placeholder="Vorname"
                    name="first_name"
                    ref={register}
                    error={errors.first_name}
                  />
                </Box>
              </Column>
              <Column size={[1, null, null, 1 / 2]}>
                <Box py={[0, null, 2]}>
                  <Input
                    placeholder="Nachname"
                    name="last_name"
                    ref={register}
                    error={errors.last_name}
                  />
                </Box>
              </Column>
            </Row>

            <Row>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box py={[0, null, 2]}>
                  <Input
                    placeholder="E-Mail"
                    name="email"
                    ref={register}
                    error={errors.email}
                  />
                </Box>
              </Column>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <Box
                  py={2}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '112px auto',
                    gridTemplateRows: 'auto auto',
                    gridTemplateAreas: `
                    'telephonePrefix telephone'
                    'errorMessage errorMessage'
                  `,
                  }}
                >
                  <Box sx={{ gridArea: 'telephonePrefix' }}>
                    <Controller
                      as={CountryCodeSelect}
                      name="telephone_prefix"
                      register={register}
                      control={control}
                      defaultValue={countryCode === 'DE' ? '+49' : '+43'}
                    />
                  </Box>
                  <Input
                    placeholder="Telefonnummer"
                    name="telephone"
                    ref={register}
                    error={errors.telephone}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeft: 'none',
                      gridArea: 'telephone',
                    }}
                  />
                </Box>
              </Column>
            </Row>

            <Row>
              <Column
                size={[1, 1, 1, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
                py={[0, null, 'unset']}
              >
                <Box py={[0, null, 2]}>
                  <Input
                    placeholder="PLZ"
                    name="zip"
                    ref={register}
                    error={errors.zip}
                  />
                </Box>
              </Column>
              <Column
                size={[1, 1, 1, 1 / 2]}
                sx={{ maxWidth: ['unset', null, '367px'] }}
                pb={[1, null, 'unset']}
              >
                <Box py={[0, null, 2]}>
                  <Select
                    options={PROPERTY_TYPE_OPTIONS}
                    onChange={(option) => {
                      setValue('property_type', option.value, {
                        shouldValidate: true,
                      })
                    }}
                    placeholder="Art der Immobilie"
                    hasError={errors.property_type}
                    isSearchable={false}
                  />
                  {errors.property_type && (
                    <ErrorMessage message={errors.property_type.message} />
                  )}
                </Box>
              </Column>
            </Row>

            <Row>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <ControlWrapper>
                  {/* <ControlHeading
                    sx={{
                      display: ['none', null, 'block'],
                    }}
                  >
                    Wert der Immobilie
                  </ControlHeading> */}
                  <Controller
                    name="valuation_customer"
                    control={control}
                    as={
                      <EuroCurrencyInput
                        badge="optional"
                        error={errors.valuation_customer}
                        placeholder="Wert der Immobilie"
                      />
                    }
                  />
                </ControlWrapper>
              </Column>
              <Column size={[1, null, null, 1 / 2]} py={[0, null, 'unset']}>
                <ControlWrapper>
                  {/* <ControlHeading
                    sx={{
                      display: ['none', null, 'block'],
                    }}
                  >
                    Ihr Auszahlungswunsch
                  </ControlHeading> */}
                  <Controller
                    name="amount"
                    control={control}
                    as={
                      <EuroCurrencyInput
                        badge="optional"
                        error={errors.amount}
                        placeholder="Ihr Auszahlungswunsch"
                      />
                    }
                  />
                </ControlWrapper>
              </Column>
            </Row>

            {showNegativeSchufa && (
              <Row>
                <Column size={[1, null, null, 1]}>
                  <Box sx={{ padding: '12px 0px' }}>
                    <Checkbox
                      noPaddingY
                      label="Negativer Schufaeintrag vorhanden."
                      name="negative_schufa"
                      ref={register}
                    />
                  </Box>
                </Column>
              </Row>
            )}

            {openInCallbackMode && (
              <Row sx={{ padding: ['4px 0', null, '12px 0'] }}>
                <Column size={[1, 1, 1, 1]} py={[0, null, 'unset']}>
                  <ControlHeading>
                    Wann können wir Sie am besten erreichen?
                  </ControlHeading>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: ['center', null, 'unset'],
                    }}
                  >
                    <MultiSelect
                      badge="optional"
                      values={[
                        {
                          label: 'Vormittags',
                          value: 'mornings',
                        },
                        {
                          label: 'Mittags',
                          value: 'midday',
                        },
                        {
                          label: 'Nachmittags',
                          value: 'afternoon',
                        },
                      ]}
                      onChange={(v) => {
                        setValue('reachability_hours', v && v.toString())
                      }}
                    />
                  </Box>
                </Column>
              </Row>
            )}

            <Box pt={2}>
              <Checkbox
                label="Hiermit bestätige ich bis auf Widerruf, dass Bambus meine Daten zur Bearbeitung meiner Anfrage speichern darf."
                name="consent_box"
                ref={register}
                error={errors.consent_box}
                alignItemsCenter
                sxExtraProps={{
                  fontSize: ['14px', null, '16px'],
                }}
              />
            </Box>

            <Box pb={2}>
              <Checkbox
                label={
                  <span>
                    <i>Optional: </i>
                    Hiermit bestätige ich bis auf Widerruf, dass Bambus meine
                    Daten für Marketing Zwecke speichern darf.
                  </span>
                }
                name="gdpr_consent"
                ref={register}
                error={errors.gdpr_consent}
                alignItemsCenter
                sxExtraProps={{
                  fontSize: ['14px', null, '16px'],
                }}
              />
            </Box>

            <Box mt={2}>
              {showError && <SubmissionFailedErrorMessage />}
              <Button
                id="modalRequestComplete"
                variant="v2Main1"
                type="submit"
                loading={loading}
                disabled={loading}
                width={['100%', null, 'unset']}
              >
                Weiter
              </Button>
            </Box>
          </form>
        </>
      )}
    </Modal>
  )
}

export default Lead
